<template>
  <div class="px-5">
    <div class="mb-4">
      <span class="font-weight-bold text-h5 mr-2"
        >Sự kiện Dạo bước công nghệ</span
      >
      <span class="mt-1">Khai trương chi nhánh 95 Trần Thiện Chánh</span>
    </div>

    <v-row>
      <v-col sm="12" md="6" @click="$refs.file.click()">
        <v-card
          class="white card-action d-flex flex-column align-center justify-center px-5 py-6"
          flat
        >
          <div class="text-h6 font-weight-medium">Nhập sản phẩm Flash Sale</div>
          <v-icon class="mt-3" color="green lighten-3" x-large
            >mdi-upload</v-icon
          >
          <div class="mt-2">
            Tải lên 2 tệp Excel tương ứng theo thứ tự phiên.
          </div>
          <input
            type="file"
            hidden
            ref="file"
            accept=".xlsx, .xls"
            multiple
            @change="importFlashSaleProductOptions"
          />
        </v-card>
      </v-col>

      <v-col sm="12" md="6" @click="exportRegister()">
        <v-card
          class="card-action d-flex flex-column align-center justify-center px-5 py-6"
          flat
        >
          <div class="text-h6 font-weight-medium">
            Xuất danh sách tham dự event quay số
          </div>
          <v-icon class="mt-3" color="orange lighten-3" x-large
            >mdi-download</v-icon
          >
        </v-card>
      </v-col>

      <v-col sm="12" md="6" @click="exportReaction()">
        <v-card
          class="card-action d-flex flex-column align-center justify-center px-5 py-6"
          flat
        >
          <div class="text-h6 font-weight-medium">
            Xuất danh sách tham dự event upload video
          </div>
          <v-icon class="mt-3" color="light-blue lighten-3" x-large
            >mdi-download</v-icon
          >
        </v-card>
      </v-col>

      <v-col sm="12" md="6" @click="exportValorant()">
        <v-card
          class="card-action d-flex flex-column align-center justify-center px-5 py-6"
          flat
        >
          <div class="text-h6 font-weight-medium">
            Xuất danh sách tham dự event Valorant
          </div>
          <v-icon class="mt-3" color="deep-purple lighten-3" x-large
            >mdi-download</v-icon
          >
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Api from "@/core/apis";

export default {
  computed: {
    eventStatusRequest() {
      return this.$store.getters["EVENT/statusRequest"];
    }
  },
  methods: {
    async exportRegister() {
      let response = await Api().get(
        "/events/dao-buoc-cong-nghe-quan-10/export/register",
        { responseType: "blob" }
      );
      const url = URL.createObjectURL(
        new Blob([response.data], {
          type: "application/vnd.ms-excel"
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "register.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async exportReaction() {
      let response = await Api().get(
        "/events/dao-buoc-cong-nghe-quan-10/export/reaction",
        { responseType: "blob" }
      );
      const url = URL.createObjectURL(
        new Blob([response.data], {
          type: "application/vnd.ms-excel"
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "reaction.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async exportValorant() {
      let response = await Api().get(
        "/events/dao-buoc-cong-nghe-quan-10/export/valorant",
        { responseType: "blob" }
      );
      const url = URL.createObjectURL(
        new Blob([response.data], {
          type: "application/vnd.ms-excel"
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "valorant.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async importFlashSaleProductOptions() {
      const excelFile = this.$refs.file.files;
      const formData = new FormData();

      Array.from(excelFile).forEach((file, index) => {
        if (index === 0) {
          formData.append("session_1", file);
        }
        if (index === 1) {
          formData.append("session_2", file);
        }
      });

      // Import request
      await this.$store.dispatch(
        "EVENT/importFlashSaleProductOptions",
        formData
      );

      // Check request status
      if (
        this.eventStatusRequest.value ===
        "success-importFlashSaleProductOptions"
      ) {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Đã nhập file thành công"
          }
        });
      }

      // Reset ref
      const input = this.$refs.file;
      input.type = "text";
      input.type = "file";
    }
  }
};
</script>

<style lang="scss" scoped>
.card-action {
  cursor: pointer;
  height: 100%;
  transition: all 0.25s ease;
  &:hover {
    box-shadow: 0 0 16px rgba(85, 85, 85, 0.15) !important;
  }
}
</style>
